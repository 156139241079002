import { Component } from '@angular/core';
import {UrlService} from "../../../../Services/url.service";
import {GoogleTagManagerService} from "../../../../Services/google-tag-manager.service";

@Component({
  selector: 'product-pipeline-main',
  templateUrl: './product-pipeline-main.component.html',
  styleUrls: ['./product-pipeline-main.component.css']
})
export class ProductPipelineMainComponent {

  constructor(public urlService: UrlService, public gtmService: GoogleTagManagerService) {}


}
