<div id="product-database-organize" class="section column center">
  <div class="section-panel gap-3">
    <div
      id="product-database-organize-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Organize Your Candidate Database"
        secondaryText="Build a powerful and easily searchable database of candidate CVs to stay organized and efficient."
      ></section-panel-header-secondary>
      <a
        [href]="urlService.register"
        target="_blank"
        (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_CREATE_ACCOUNT)"
      >
        <div class="button-action-call"> Start Organizing Your CVs Now! </div>
      </a>
    </div>
    <div class="section-right center-vertical">
      <div id="product-database-organize-image" class="section-image">
        <img src="assets/images/product-database-organise.svg" />
      </div>
    </div>
  </div>
</div>




