<div id="product-job-matching-skills" class="section column center">
  <div class="section-panel gap-5">
    <div
      id="product-job-matching-skills-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Relevant Skill Extraction"
        secondaryText="HireFox’s Job Matching Assistant not only
          identifies direct skill matches from the job description
          but also recognizes related skills that candidates bring to the table."
      ></section-panel-header-secondary>
      <a
        [href]="urlService.register"
        target="_blank"
        (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_CREATE_ACCOUNT)"
      >
        <div class="button-action-call"> Discover Smart Skill Matching </div>
      </a>
    </div>
    <div class="section-right center-vertical">
      <div id="product-job-matching-skills-image" class="section-image">
        <img src="assets/images/product-job-matching-skills.svg" />
      </div>
    </div>
  </div>
</div>



