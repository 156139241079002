<div id="product-job-matching-requirements" class="section column center">
  <div class="section-panel gap-3">
    <div class="section-left center-vertical">
      <div id="product-job-matching-requirements-image" class="section-image">
        <img src="assets/images/product-job-matching-requirements.svg" />
      </div>
    </div>
    <div
      id="product-job-matching-requirements-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Beyond Keyword Matching: AI That Understands Candidates"
        [secondaryText]="SECONDARY_TEXT"
      ></section-panel-header-secondary>
      <a
        [href]="urlService.register"
        target="_blank"
        (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_CREATE_ACCOUNT)"
      >
        <div class="button-action-call"> Explore AI-Driven Candidate Analysis </div>
      </a>
    </div>
  </div>
</div>


