<div id="product-pipeline-collaboration" class="section column center">
  <div class="section-panel gap-5">
    <div class="section-left center-vertical">
      <div id="product-pipeline-collaboration-image" class="section-image">
        <img src="assets/images/product-pipeline-collaboration.svg" />
      </div>
    </div>
    <div
      id="product-pipeline-collaboration-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Real-time Collaboration"
        secondaryText="Hirefox enables multiple users to work simultaneously
          on each job, with changes updating in real time."
      ></section-panel-header-secondary>
      <a
        [href]="urlService.register"
        target="_blank"
        (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_CREATE_ACCOUNT)"
      >
        <div class="button-action-call"> Start Collaborating Now </div>
      </a>
    </div>
  </div>
</div>





