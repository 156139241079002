<div id="product-job-matching-concerns" class="section column center">
  <div class="section-panel gap-5">
    <div class="section-left center-vertical">
      <div id="product-job-matching-concerns-image" class="section-image">
        <img src="assets/images/product-job-matching-concerns.svg" />
      </div>
    </div>
    <div
      id="product-job-matching-concerns-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Identifying Potential Concerns"
        secondaryText="HireFox is proactively checking for candidate
          red flags by highlighting areas of caution, such as mismatches
          in experience levels or gaps in necessary skills."
      ></section-panel-header-secondary>
      <a
        [href]="urlService.register"
        target="_blank"
        (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_CREATE_ACCOUNT)"
      >
        <div class="button-action-call"> Start Now, and Address Concerns Before They Arise </div>
      </a>
    </div>
  </div>
</div>


