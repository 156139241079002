<div id="product-resume-analysis-profiling" class="section column center">
  <div class="section-panel gap-5">
    <div class="section-left center-vertical">
      <div id="product-resume-analysis-profiling-image" class="section-image">
        <img src="assets/images/product-resume-analysis-profiling.svg " />
      </div>
    </div>
    <div
      id="product-resume-analysis-profiling-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Effortless Candidate Profiling"
        secondaryText="Skip the Calculations, Get the Facts"
      ></section-panel-header-secondary>
      <div class="content-text">
        HireFox automatically extracts key information
        such as total years of experience, job tenure,
        seniority level, and  gaps in employment
        without manual calculations or guesswork.
      </div>
      <a
        [href]="urlService.register"
        target="_blank"
        (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_CREATE_ACCOUNT)"
      >
        <div class="button-action-call"> Automate Your CV Screening </div>
      </a>
    </div>
  </div>
</div>


