import { Component } from '@angular/core';
import {UrlService} from "../../../../Services/url.service";
import {GoogleTagManagerService} from "../../../../Services/google-tag-manager.service";

@Component({
  selector: 'product-job-matching-page-skills',
  templateUrl: './product-job-matching-page-skills.component.html',
  styleUrls: ['./product-job-matching-page-skills.component.css']
})
export class ProductJobMatchingPageSkillsComponent {

  constructor(public urlService: UrlService, public gtmService: GoogleTagManagerService) {}

}
