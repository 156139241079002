<div id="product-database-filters" class="section column center">
  <div class="section-panel gap-5">
    <div class="section-left center-vertical">
      <div id="product-database-filters-image" class="section-image">
        <img src="assets/images/product-database-filters.svg" />
      </div>
    </div>
    <div
      id="product-database-filters-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Powerful Auto-generated Filters"
        secondaryText="HireFox offers a wide range of filters
          that allow you to effortlessly search for your
          chosen candidates. These filters are automatically
          generated based on the content of the CVs uploaded
          to the system."
      ></section-panel-header-secondary>
      <a
        [href]="urlService.register"
        target="_blank"
        (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_CREATE_ACCOUNT)"
      >
        <div class="button-action-call"> Explore Filters—Sign Up Free  </div>
      </a>
    </div>
  </div>
</div>



