<div id="product-pipeline-notes" class="section column center">
  <div class="section-panel gap-5">
    <div class="section-left center-vertical">
      <div id="product-pipeline-notes-image" class="section-image">
        <img src="assets/images/product-pipeline-notes.svg" />
      </div>
    </div>
    <div
      id="product-pipeline-notes-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Keep Notes"
        secondaryText="Add notes for each candidate and keep track of every little detail."
      ></section-panel-header-secondary>
      <a
        [href]="urlService.register"
        target="_blank"
        (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_CREATE_ACCOUNT)"
      >
        <div class="button-action-call"> Start Documenting Candidate Insights </div>
      </a>
    </div>
  </div>
</div>




